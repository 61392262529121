export const UrlString = {
 BASE_URL : 'http://localhost:8000/',
}

export const TronConfig={
  TRONGRID_API : "https://api.shasta.trongrid.io",
 // TRONGRID_API : "https://api.trongrid.io",
  // ContractAddress : "TGVdfsTtFQ7pRsDcoRyumQnFMoYwpQZM6w",
  // FOUNDATION_ADDRESS : "TGVdfsTtFQ7pRsDcoRyumQnFMoYwpQZM6w",
  // fullHost: 'https://api.trongrid.io',
  // privateKey: '6a88ffcd082497726beda269aad7ff6a9ac83c03a9f7ccd01b354a49e26ddc71'
  //TOKENS_API: "https://shastapi.tronscan.org/api/account?address=",
 TOKENS_API: "https://apilist.tronscan.org/api/account?address=",

}



    