
import React,{Component} from 'react';
import { TronConfig } from '../../config/apiRoutes';
import Utils from '../../config/util';
import TronWeb from "tronweb";
import axios from 'axios';
import Notify from './../SharedModule/Notify';
import NotifyResponse from './../SharedModule/NotifyResponse';
const TronTxDecoder = require('tron-tx-decoder');
const decoder = new TronTxDecoder({ mainnet: true });
var c = 0;
var t;
var timer_is_on = 0;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: localStorage.getItem('loggedIn'),
      persons:{},
      address_valid:false,
      errors:'',
      receiver_address:'',
      TrasferMessage:'',
      countLoop:0,
      LoopTime:1000,
      notify:{
        visible:false,
                type:'default',
                msg : ' Tron Message Connected'
      },
      notifyLoader:{
        visible:false,
                type:'default',
                msg : ''
      },
      
    };
    this.handleValidation = this.handleValidation.bind(this);
  }        

  async componentDidMount() {
      if(localStorage.getItem('loggedIn')){
        setTimeout(() => {
        this.getAllTokens();
      },2000);
       }else if(localStorage.getItem('loggedIn')=== false){
       }
     
    try{ 
     await new Promise(resolve => {
        const tronWebState = {
          installed: !!window.tronWeb,
          loggedIn: window.tronWeb && window.tronWeb.ready
        };
        
        // console.log(tronWebState.installed , tronWebState.loggedIn,"working login",localStorage.getItem('loggedIn'));
        if (tronWebState.installed && tronWebState.loggedIn && !localStorage.getItem('loggedIn')) {
          setTimeout(() => {
          //  console.log("working login")
              this.getAllTokens();
        
            },2000);
          this.setState({
            tronWeb: tronWebState
          });
          return resolve();
        }

        let tries = 0;
        
        const timer = setInterval(() => {
          if (2e4 <= tries) {
            // const TRONGRID_API = TronConfig.TRONGRID_API
            window.tronWeb = new TronWeb(
              TronConfig.TRONGRID_API,
              TronConfig.privateKey
            );
            this.setState({
              tronWeb: tronWebState
            });
            clearInterval(timer);
            return resolve();
          }
          
          
          return resolve();
        },100);
      });
      
      await Utils.setTronWeb(window.tronWeb);
      
    }catch(e){
      // console.log(e);
    } 

    setTimeout(()=>{
    if( this.state.walletddress  ){
      this.setState({
        notifyLoader:{
          visible:true,
                  type:'default',
                  msg : 'Tron Connected '
        },
      })
    }else{
      this.setState({
        notifyLoader:{
          visible:true,
                  type:'default',
                  msg : 'Tron Not Connected '
        },
      })
    }
  },4000);

  }
  
async getAllTokens(){
  const trondata = await window.tronWeb.trx;
     const account = await window.tronWeb.trx.getAccount();
     const accountkey=trondata.tronWeb.defaultAddress.base58;
    //  console.log(account,trondata,accountkey);
     localStorage.setItem('myWalletAddress',accountkey);
  axios.get(TronConfig.TOKENS_API+accountkey)
  .then(res => {
    const persons = res.data;
    console.log(res.data.address);
    this.setState({
       ...this.state,
      newpersons:res.data.tokens,
      walletddress:res.data.address,
    });
     console.log(res.data.tokens); 
  });

}

 async handleValidation(event){
  event.preventDefault();
 
  if(this.state.receiver_address.length > 0){
    try{ 
         await window.tronWeb.contract().at(this.state.receiver_address);
     }catch(e){
      if(e.toString()==="Invalid contract address provided"){
        this.setState({
          address_valid:false,
        errors:'Please enter valid Swap address'
      })
      return false;
      }else if(this.state.receiver_address===this.state.walletddress){
        this.setState({
          address_valid:false,
        errors:'Swap Address is same account'
      })
      return false;
      }else{
        this.setState({
          address_valid:true,
          errors:'Its Valid Swap address'
        })
      }
    } 
  }else{

  this.setState({
    address_valid:false,
    errors:'Please enter sweep address'
  })}
}



handleLoopTokens=async ()=>{
  console.log(this.state.newpersons[0]);
 
  console.log(this.state.LoopTime);
  if(this.state.receiver_address && this.state.address_valid){
  if(this.state.newpersons[0] && this.state.newpersons[0].tokenType==="trc10" && this.state.newpersons[0].balance !== "0" ){
 
    

 for(let i=this.state.newpersons.length-1;i >= 0;i--){
   
   if(this.state.newpersons[i].balance && this.state.newpersons[i].tokenName){
     
     // this.handleTransferTokens(this.state.newpersons[i].tokenType,this.state.newpersons[i].balance,this.state.newpersons[i].tokenAbbr,this.state.newpersons[i].tokenId);
   let tokentype=this.state.newpersons[i].tokenType;
  let transferbalance=this.state.newpersons[i].balance;
  let tokenAbbr =this.state.newpersons[i].tokenAbbr;
  let tokenid=this.state.newpersons[i].tokenId;
 
  console.log(tokenid,transferbalance,this.state.receiver_address,tokentype);
  if(tokenid  ){
       console.log(tokenid,tokentype,"work");
       if(tokentype === "trc10" && tokenAbbr !="trx" ){
        try{
         console.log(this.state.LoopTime);
     const trondata= await window.tronWeb.trx.sendToken(this.state.receiver_address,transferbalance,tokenid).catch((error) => {
      console.log(error);
      this.setState({
        ...this.state,
        notify:{
          visible:true,
          type:'default',
          msg : error.message === "AccountResourceInsufficient error" ? "Insufficient Balance " : error === "TRANSACTION_EXPIRATION_ERROR"? "transaction expired":error,
 
        },
      })
      setTimeout(() => {
        this.setState({
          ...this.state,
          notify:{
            visible:false,
                    type:'default',
                    msg : ''
          },
        })
      },1000);
    });
       console.log(trondata);
    if(trondata.result){
      
      this.setState({
        ...this.state,
        notify:{
          visible:true,
          type:'default',
          msg : 'Tokens transferred'
        }
      })
        setTimeout(() => {
          this.getAllTokens();
          this.setState({
            notify:{
              visible:false,
                      type:'default',
                      msg : ''
            },
          })
        },3000);
    }else if(trondata.code==="BANDWITH_ERROR"){
      this.setState({
        ...this.state,
        notify:{
         
          visible:true,
          type:'default',
          msg : 'Insufficient Balance '
        },
      })
      setTimeout(() => {
        this.setState({
          ...this.state,
          notify:{
            visible:false,
                    type:'default',
                    msg : ''
          },
        })
      },3000);
    }else{
      this.setState({
        ...this.state,
        notify:{
         
          visible:true,
          type:'default',
          msg : 'trigger smart contract error'
        },

      })
      setTimeout(() => {
        this.setState({
          ...this.state,
          notify:{
            visible:false,
                    type:'default',
                    msg : ''
          },
        })
      },3000);

    }
      
    } catch(error) {
      console.error("trigger smart contract error",error)
  }
  
}

    else if(tokentype === "trc20") {
        try {
          
          let contract = await window.tronWeb.contract().at(tokenid);
                      // console.log(contract);
                       let result= await contract.transfer(
                       this.state.receiver_address, //address _to
                        transferbalance  //amount
                      ).send({
                        feeLimit: 300000000
                    }).catch((error) => {
                      console.log(error,error.error);
                      this.setState({
                        ...this.state,
                        notify:{
                          visible:true,
                          type:'default',
                          msg : error.message === "AccountResourceInsufficient error" ? "Insufficient Balance " : error === "TRANSACTION_EXPIRATION_ERROR"? "transaction expired":error,

                        },
                      })
                      setTimeout(() => {
                        this.setState({
                          ...this.state,
                          notify:{
                            visible:false,
                                    type:'default',
                                    msg : ''
                          },
                        })
                      },3000);
                   
                    });
                  
           const decodedInput = await decoder.decodeResultById(result);
           console.log(result,decodedInput)
          if(result){
            this.setState({
              ...this.state,
              notify:{
                visible:true,
                type:'default',
                msg : "Tokens transferred"
              },
            })
            // console.log("transfer trc20");
            setTimeout(() => {
              this.getAllTokens();
            },1000);
            setTimeout(() => {
              this.setState({
                ...this.state,
                notify:{
                  visible:false,
                          type:'default',
                          msg : ''
                },
              })
            },3000);
          }
        } catch(error) {
            console.error("trigger smart contract error",error)
        }

      }
      else{
       let tradeobj = await window.tronWeb.transactionBuilder.sendTrx(
        this.state.receiver_address,
        transferbalance,
        this.state.walletddress
        );
        const signedtxn = await window.tronWeb.trx.sign(tradeobj).catch((error) => {
          this.setState({
           ...this.state,
           notify:{
             visible:true,
             type:'default',
             msg : error.message ?error.message:error,
           },
         })
         setTimeout(() => {
           this.setState({
            ...this.state,
             notify:{
               visible:false,
                       type:'default',
                       msg : ''
             },
            
           })
         },1000);
       });
       const receipt = await window.tronWeb.trx.sendRawTransaction(signedtxn).catch((error) => {
        console.log(error);
       this.setState({
        ...this.state,
        notify:{
          visible:true,
          type:'default',
          msg : error.message === "AccountResourceInsufficient error" ? "Insufficient Balance " : error === "TRANSACTION_EXPIRATION_ERROR"? "transaction expired":error,

          },
      })
      setTimeout(() => {
        this.setState({
          notify:{
            visible:false,
                    type:'default',
                    msg : ''
          },
         
        })
      },1000);
    });
        console.log(tradeobj,signedtxn,receipt);
        if(receipt && receipt.result){
          this.setState({
            ...this.state,
            notify:{
              visible:true,
              type:'default',
              msg : 'All TRX transferred'
            }
          })
            setTimeout(() => {
              this.getAllTokens();
              this.setState({
                notify:{
                  visible:false,
                          type:'default',
                          msg : ''
                },
              })
            },3000);
        }else if(receipt){
          this.setState({
            ...this.state,
            notify:{
              visible:true,
              type:'default',
              msg : 'Insufficient Balance'
            },
          })
        }
      }
    } 
  }
 }
}else{
  this.setState({
    ...this.state,
    notify:{
      visible:true,
      type:'default',
      msg : 'Insufficient TRX Balance',
       
    },
    
  })
  console.log(this.state.countLoop);
  setTimeout(() => {
    this.setState({
      notify:{
        visible:false,
                type:'default',
                msg : ''
      },
    })
  },1000);

}
}else{
  this.setState({
    notify:{
      visible:true,
              type:'default',
              msg : this.state.errors? this.state.errors: 'Please enter valid sweep address'
    },
  })
  setTimeout(() => {
    this.setState({
      notify:{
        visible:false,
                type:'default',
                msg : ''
      },
    })
  },3000);
}

}



 

handleSingleTransferTokens=async(e)=>{
  if(this.state.receiver_address &&  this.state.address_valid){
 e.preventDefault()
 let value=e.target.value;
 let sprit=value.split(',', );
 let transferbalance=sprit[0];
 let tokentype=sprit[1];
 let tokenid=sprit[2];
 let tokenAbbr =sprit[3];
 if(tokenid  ){
    //try{
      if(tokentype === "trc10" && tokenAbbr !="trx" ){
    const trondata= await window.tronWeb.trx.sendToken(this.state.receiver_address,transferbalance,tokenid).catch((error) => {
      this.setState({
        ...this.state,
        notify:{
          visible:true,
          type:'default',
          msg : error.message === "AccountResourceInsufficient error" ? "Insufficient Balance " : error === "TRANSACTION_EXPIRATION_ERROR"? "transaction expired":error,

        },
      })
      setTimeout(() => {
        this.setState({
          notify:{
            visible:false,
                    type:'default',
                    msg : ''
          },
        })
      },3000);
    });
    if(trondata.result){
   this.setState({
     TrasferMessage:"",
     loader:true
   })
   this.setState({
     ...this.state,
     notify:{
       visible:true,
       type:'default',
       msg : 'All Tokens transferred '
     }
   })
     setTimeout(() => {
       this.getAllTokens();
       this.setState({
         notify:{
           visible:false,
                   type:'default',
                   msg : ''
         },
       })
     },3000);
   }else{
     this.setState({
       ...this.state,
       notify:{
         visible:true,
         type:'default',
         msg : 'trigger smart contract error'
       }
     })
   }
     }
     
     else if(tokentype === "trc20") {
       
       try {
           let contract = await window.tronWeb.contract().at(tokenid);
            let result= await contract.transfer(
            this.state.receiver_address, //address _to
             transferbalance  //amount
           ).send({
             feeLimit: 300000000
         }).catch((error) => {
         console.log(error);
          this.setState({
            ...this.state,
            notify:{
              visible:true,
              type:'default',
              msg : error.message === "AccountResourceInsufficient error" ? "Insufficient Balance " : error === "TRANSACTION_EXPIRATION_ERROR"? "transaction expired":error,

            },
          })
          setTimeout(() => {
            this.setState({
              notify:{
                visible:false,
                        type:'default',
                        msg : ''
              },
            })
          },3000);
        });
         
         // console.log(result);
         const decodedInput = await decoder.decodeResultById(result);
        //console.log(decodedInput)
         if(result){
           this.setState({
             ...this.state,
             notify:{
               visible:true,
               type:'default',
               msg : decodedInput.decodedOutput,
             }
           })
           setTimeout(() => {
             this.getAllTokens();
             this.setState({
               notify:{
                 visible:false,
                         type:'default',
                         msg : ''
               },
             })
           },5000);
         }else{
           this.setState({
             ...this.state,
             notify:{
               visible:true,
               type:'default',
               msg : 'trigger smart contract error Error: Transaction not found',
             }
           })
           setTimeout(() => {
             this.getAllTokens();
             this.setState({
               notify:{
                 visible:false,
                         type:'default',
                         msg : ''
               },
             })
           },3000);
         }
       } catch(error) {
           console.error("trigger smart contract error",error)
       }
     }
     else{
      if(transferbalance !=="0"){
      let tradeobj = await window.tronWeb.transactionBuilder.sendTrx(
       this.state.receiver_address,
       transferbalance,
       this.state.walletddress
       );
       const signedtxn = await window.tronWeb.trx.sign(tradeobj).catch((error) => {
        this.setState({
          ...this.state,
          notify:{
            visible:true,
            type:'default',
            msg : error.message ?error.message:error,
          },
        })
        setTimeout(() => {
          this.setState({
            notify:{
              visible:false,
                      type:'default',
                      msg : ''
            },
          })
        },3000);
      });
      const receipt = await window.tronWeb.trx.sendRawTransaction(signedtxn).catch((error) => {
        this.setState({
          ...this.state,
          notify:{
            visible:true,
            type:'default',
            msg : error.message ?error.message:error,
          },
          countLoop:this.state.countLoop +1,
        })
        console.log(this.state.countLoop);
        setTimeout(() => {
          this.setState({
            notify:{
              visible:false,
                      type:'default',
                      msg : ''
            },
          })
        },3000);
      });
       console.log(tradeobj,signedtxn,receipt);
       if(receipt && receipt.result){
         this.setState({
           ...this.state,
           notify:{
             visible:true,
             type:'default',
             msg : 'All TRX transferred '
           }
         })
         setTimeout(() => {
         this.getAllTokens();
         this.setState({
           notify:{
             visible:false,
                     type:'default',
                     msg : ''
           },
         })
       },3000);
       }else if(receipt.code==="BANDWITH_ERROR"){
         this.setState({
           ...this.state,
           notify:{
             visible:true,
             type:'default',
             msg : 'Insufficient Balance '
           }
         })
         setTimeout(() => {
          this.setState({
            notify:{
              visible:false,
                      type:'default',
                      msg : ''
            },
          })
        },3000);
       }
      }else{
        this.setState({
          ...this.state,
          notify:{
            visible:true,
            type:'default',
            msg : 'Insufficient Balance'
          }
        })
        setTimeout(() => {
          this.setState({
            notify:{
              visible:false,
                      type:'default',
                      msg : ''
            },
          })
        },3000);

      }


     }
     
   }
 
}else{

 this.setState({
   notify:{
     visible:true,
             type:'default',
             msg : this.state.errors?this.state.errors:'Please enter valid sweep address'
   },
 })
 setTimeout(() => {
   this.setState({
     notify:{
       visible:false,
               type:'default',
               msg : ''
     },
   })
 },3000);
}
}


TokenBlance=(balance,decimal)=>{
 let sum = 0;
 let calculatedecimal =1;
for(let i=1;i<=decimal;i++)
{
  calculatedecimal =calculatedecimal+""+sum;
}
return (parseFloat(balance/calculatedecimal)).toFixed(6);
}

  handleTokenList=()=>{
       if(this.state.newpersons && this.state.newpersons.length ){
       return this.state.newpersons.map((item,i) => (
                           <tr key={i} >
                              <td>
                                {item.tokenLogo.match(/fakepath/g)    || item.tokenLogo === '' ?
                                <img src="/assets/images/tron.jpg" />:
                                <img src={item.tokenLogo}/>
                                 }
                                {/* <img src={this.checkImageURL(item.tokenLogo)} alt="" /> */}
                              </td>
                              <td>{item.tokenAbbr.toUpperCase()} | {this.TokenBlance(item.balance,item.tokenDecimal)}</td>
                              <td>
                              <button type="button"  className="btn-top px-3 sweep-btn"   value={item.balance +","+item.tokenType+","+item.tokenId+","+item.tokenAbbr}  onClick={this.handleSingleTransferTokens}  >Sweep</button>
                                <span style={{color:"red"}}></span>
                              </td>
                            </tr>
         ));
     }
  }
  
  
  render() {
   
   return  <div> 
     
         <div className="loading" style={{display:"none"}}>Loading&#8230;</div>
	<header className="px-md-3 px-lg-5">
      <nav
        className="navbar navbar-dark navbar-expand-lg bg-faded justify-content-between px-lg-3"
      >
        <a href="/" className="navbar-brand"
          ><img src="assets/images/logo.png"
        /></a>
        <button
          className="navbar-toggler mt-2"
          type="button"
          data-toggle="collapse"
          data-target="#collapsingNavbar3"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        
        <div id="mySidenav" className="sidenav">
          <a  className="closebtn" 
            >&times;</a
          >
          <div className="mobile-unlock-btn d-flex justify-content-center">
            <a href="#">
              <span className="d-flex align-items-center">
                <span className="mr-3"><img src="assets/images/wallet.png" /></span>
                <span>{this.state.walletddress?this.state.walletddress:"UNLOCK WALLET"}</span>
              </span>
            </a>
          </div>
        </div>
      </nav>
    </header>
    <section id="slider">
      <div className="container">
      <form onSubmit={this.handleValidation}>
        <div className="input-section">
          <div className="row justify-content-between align-items-center">
            <div
              className="col-12 col-sm-3 col-md-3 col-lg-3 margin-y16 order-md-2 order-lg-1"
            >
              <div className="d-flex justify-content-center align-items-center">
                <div className="lucky-section d-flex flex-column">
                 <ul className="navbar-nav justify-content-end">
		            <li className="nav-item my-auto">
		              <span  className="btn-top btn-new px-3">
		                <span className="px-3 d-flex">
		                  <span className="my-auto pr-2"
		                    ><img src="assets/images/wallet.png" height="18px"
		                  /></span>

<span>{this.state.walletddress?this.state.walletddress.substring(0,5)+"...."+this.state.walletddress.substring(28,34):"UNLOCK WALLET"}</span>                      </span
		                >
                    </span>
		            </li>
		          </ul> 
          	
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-6 col-lg-6 col-md-6 margin-y16 order-md-3 order-lg-3"
            >
              
              <div className="betting-section d-flex flex-column">
                
                <input style={{marginTop:"25px"}}
                  className="num px-2 bet-input"
                  id="betAmount" type="text"
                  value={this.state.value} onChange={(e)=>this.setState({receiver_address:e.target.value})}
                  placeholder="Enter Swap Address"
                />
              </div>
              {this.state.address_valid?
              <label style={{color:"green"}}>{this.state.errors}</label>:
              <label style={{color:"red"}}>{this.state.errors}</label>
               }
            </div>
            
            <div
              className="col-12 col-sm-3 col-lg-3 col-md-3 margin-y16 order-md-3 order-lg-3"
            >
              <section className="main-btn-sec">
                <div className="text-center">
                  <input type="submit" value="Validate Address" className="btn-main" id="submitBet"
                    />
                  <button
                    type="button"
                    className="btn btn-primary"
                    hidden
                    data-toggle="modal"
                    data-target="#resultModal"
                    id="showResult"
                  >
                    LAUNCH
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
        </form>
        
        {this.state.notifyLoader.visible?(<Notify attr={this.state.notifyLoader}/>):('')}
        {this.state.notify.visible?(<NotifyResponse attr={this.state.notify}/>):('')}
        <div className="instructions">
          <section className="activities view-section">
            <div className="container" style={{marginTop:"40px"}}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-statistics"
                      role="tabpanel"
                      aria-labelledby="pills-all-bets-tab"
                    >
                      <div className="table-responsive">
                        <table className="table" id="showUserData">
                          <thead>
                            <tr>
                              <th scope="col">Image</th>
                              <th scope="col">Token | TRX | Coin</th>
                              <th scope="col">
                              Sweep
                              </th>
                            </tr>
                          </thead>
                          <tbody  >
                          {this.handleTokenList()}
                          </tbody>
                        </table>
                        {this.state.newpersons ?
                        <div>
                          <a onClick={this.handleLoopTokens} className="btn-top px-3 sweep-btn" style={{textAlign:"center",width:"100%",cursor:"pointer"}}>Sweep All</a>
                        </div>
                         :<div>
                         <a  className="btn-top px-3 sweep-btn" style={{textAlign:"center",width:"100%",cursor:"pointer"}}>Loading...</a>
                       </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
         </div>
}

}
export default Home;
