import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class NotifyResponse extends React.Component{
   componentDidMount(){
    // console.log(this.props);
    if(this.props.attr.type==='success' && this.props.attr.visible){
      toast.success(this.props.attr.msg,{autoClose:2000});
    }else if(this.props.attr.type==='warning' && this.props.attr.visible){
      toast.warn(this.props.attr.msg,{autoClose:2000});
    }else{
      toast(this.props.attr.msg,{autoClose:2000});
    }
  } 

  render(){
    return(
      <React.Fragment>
         <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover/>
      </React.Fragment>
    )
  } 
}

export default NotifyResponse