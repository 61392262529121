

import React,{ Component } from 'react';
import TronWeb from "tronweb";
import {TronConfig} from './config/apiRoutes';
import Utils from './config/util';
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import Home  from './components/UserModule/Home';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tronWeb: {
        installed: false,
        loggedIn: false
      },
      subscribe:'',
      address:'',
      myWalletAddress:'0000000'
    };
  }

  async componentDidMount() {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("balance");
    
    
    let self =this;
    window.addEventListener('message', function (e) {
 
      if(e){
      if (e.data.message && e.data.message.action === "setAccount") {
          if(localStorage.getItem('myWalletAddress')){
           if(e.data.message.data.address === localStorage.getItem('myWalletAddress')){
          }else {
              window.location.href=window.location.origin
          }
          }
      }
    }
      
   })
    

    try{ 
     await new Promise(resolve => {
        const tronWebState = {
          installed: !!window.tronWeb,
          loggedIn: window.tronWeb && window.tronWeb.ready
        };
        // console.log(tronWebState.installed , tronWebState.loggedIn);
        if (tronWebState.installed && tronWebState.loggedIn) {
          this.setState({
            tronWeb: tronWebState
          });
          this.setState({
            tronWeb: tronWebState
          },()=>{
            this.setState({
              ...this.state,
              notify:{
                visible:true,
                type:'default',
                msg : 'Connected'
              }
            })
          });
          

          return resolve();
        }

        let tries = 0;
        const timer = setInterval(() => {
          if (2e4 <= tries) {
            window.tronWeb = new TronWeb(
              TronConfig.fullHost,
              TronConfig.privateKey
            );
            this.setState({
              tronWeb: tronWebState
            });
            clearInterval(timer);
            return resolve();
          }
          
          tronWebState.installed = !!window.tronWeb;
          tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;
          
          if (!tronWebState.installed) {
            return tries++;
          }
          return resolve();
        },100);
      });
       
       if(!this.state.tronWeb.loggedIn) {  
        
        
        window.tronWeb.on('addressChanged', () => {
          if(this.state.tronWeb.loggedIn)
              return;

          this.setState({
              tronWeb: {
                  installed: true,
                  loggedIn: true
              }
          },()=>{
            console.log('login',this.state.tronWeb.loggedIn,"working add ");
          });
      });
    }
    const trondata = await window.tronWeb.trx;
    const accountkey=trondata.tronWeb.defaultAddress.base58;
      localStorage.setItem('myWalletAddress',accountkey);
      localStorage.setItem('loggedIn',this.state.tronWeb.loggedIn);
      // console.log("login check ",accountkey);
      await Utils.setTronWeb(window.tronWeb);
     
    }catch(e){
      console.log(e);
    } 
  }
 
  render() {
    return (
      <React.Fragment>
    <BrowserRouter>
    <div className="content"> 
            <Switch>
            <Route exact path="/" component={Home} />
            </Switch>
            </div>   
    </BrowserRouter>
    {/* {this.state.notify.visible?(<Notify attr={this.state.notify}/>):('')} */}
    </React.Fragment>
  );
}
}
export default App;
